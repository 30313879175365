import React, { useEffect, useState } from 'react';
import './insightsCard.css';
import config from '../config';

function InsightsCard({ data, onCardSelect }) {

    const [cardData, setCardData] = useState(data);
    const [isRotated, setIsRotated] = useState(false);
    const [isSwaped, setIsSwaped] = useState(false);
    const [activeCard, setActiveCard] = useState({});

    useEffect(() => {
        const updatedCardData = {
            "work_count": 0,
            "outcome": 0,
            "project_status": {
                "upcoming": 0,
                "ongoing": 0,
                "completed": 0
            },
            "title": 'Art Direction',
            color: 'rgba(189, 96, 3, 1)',
            hoverColor: 'rgba(189, 96, 3, 0.2)',
            footerColor: 'rgba(255, 245, 224, 0.4)',
            icon: config.artDirection
        }
        isSwaped ? setCardData(updatedCardData) : setCardData(data);
    }, [data, isSwaped]);

    const handleClick = () => {
        let data = {
            title: cardData?.title,
            color: cardData?.color
        }
        setActiveCard(data);
        if (onCardSelect) {
            onCardSelect(data);
        }
    }

    const rotate = () => {
        setIsSwaped(!isSwaped);
        setIsRotated(true);
        setTimeout(() => {
            setIsRotated(false);
        }, 1000)
    }

    const removeFilter = () => {
        setActiveCard({});
        if (onCardSelect) {
            onCardSelect(null);  // Pass null to indicate no card is active
        }
    }

    return (
        <div className='insight_card'
            style={{
                background: cardData?.title === 'Overall Total' ? 'rgba(47, 46, 46, 1)' : 'rgba(255, 255, 255, 1)',
                '--card-border-color': cardData?.hoverColor,
                color: cardData?.color,
                transform: isRotated ? 'rotateY(-180deg)' : 'rotateY(0deg)',
                transition: 'transform 1s ease',
                border: (activeCard?.title === cardData?.title) ? `2px solid ${cardData?.color}` : '1px solid transparent'
            }}>
            <div className="insight_card-title">
                {cardData?.icon && (<img src={cardData?.icon} alt='icon' style={{ width: '13%' }} />)}
                <p style={{ width: '9.3rem' }} onClick={handleClick}>{cardData?.title}</p>
                {cardData?.title !== 'Overall Total' && (
                    activeCard.title ? (
                        <img src={config.close} alt='' onClick={removeFilter} />
                    ) : (
                        <img src={config.swap} alt='' onClick={rotate} />
                    )
                )}
            </div>
            <div className="card-body" style={{ color: cardData?.title === 'Overall Total' ? 'white' : 'rgba(67, 67, 67, 1)' }}
                onClick={handleClick}>
                <div className='card-body-content'>
                    <span className='insight_countTitle'>Work Count</span>
                    <hr />
                    <span className='insight_countValue'>{cardData?.work_count}</span>
                </div>
                <div className='card-body-content'>
                    <span className='insight_countTitle'>Outcome</span>
                    <hr />
                    <span className='insight_countValue'>{cardData?.outcome}</span>
                </div>
            </div>
            {cardData?.project_status && (<div className='card-footer' style={{ background: cardData?.footerColor }}
                onClick={handleClick}>
                <div className='card-footer-wraper'>
                    <div style={{ borderRight: `1px solid ${cardData?.color}` }}>
                        <span className='insight_card-footer-title'>Ongoing</span>
                        <span className='insight_card-footer-value'>{cardData?.project_status.ongoing}</span>
                    </div>
                    <div style={{ borderRight: `1px solid ${cardData?.color}` }}>
                        <span className='insight_card-footer-title'>Upcoming</span>
                        <span className='insight_card-footer-value'>{cardData?.project_status.upcoming}</span>
                    </div>
                    <div>
                        <span className='insight_card-footer-title'>Completed</span>
                        <span className='insight_card-footer-value'>{cardData?.project_status.completed}</span>
                    </div>
                </div>
            </div>)}
        </div>
    )
}

export default InsightsCard;
