import React from 'react';
import './tabledata.css';

function TableData({data}) {
  return (
    <table className='rounded-corners'>
      <thead>
        <tr>
          <th>Vertical</th>
          <th>Completed</th>
          <th>Ongoing</th>
          <th>Upcoming</th>
          <th>Total Work Count</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            {Object.keys(row).map((key, i) =>
              row[key] !== null ? <td key={i}>{row[key]}</td> : <td key={i}></td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default TableData;
