import './App.css';
import Sidebar from './component/sidebar.js';
import Dashboard from './component/dashboard.js';
import Insights from './component/insights.js';
import { useState } from 'react';


function App() {
  const [activeView, setActiveView] = useState('dashboard');
  const handleSwitchView = (view) => {
    setActiveView(view);
  };

  return (
    <div className="App">
      <Sidebar activeView={activeView} onSwitchView={handleSwitchView}/>
      {activeView === 'dashboard'? <Dashboard /> : <Insights/>}
    </div>
  );
}

export default App;
