import React, { useState } from 'react';
import config from '../config';
import './table.css'
import TableData from './tabledata';

function Table() {
    const tabs = ['Medium', 'Division', 'Artist', 'Work Type', 'Task Type'];
    const [activeTab, setActiveTab] = useState('Medium');

    var tableData = [
        { "Vertical": "Artwork", "Completed": null, "Ongoing": null, "Upcoming": null, "Total Work Count": null },
        { "Vertical": "Video", "Completed": null, "Ongoing": null, "Upcoming": null, "Total Work Count": null },
        { "Vertical": "Content", "Completed": null, "Ongoing": null, "Upcoming": null, "Total Work Count": null },
        { "Vertical": "Web", "Completed": null, "Ongoing": null, "Upcoming": null, "Total Work Count": null }
      ]
      
    return (
        <div>
            <div className='action_wrapper'>
                <div className='tabs'>
                    {tabs.map((item, index) => {
                        return <span
                            className={`${item === activeTab ? 'activeTab' : 'inactive'}`} key={index}
                            onClick={() => { setActiveTab(item) }}>
                            {item}</span>
                    })}
                </div>
                <div className='downloadFilter_section'>
                    <div className='header-action-1' style={{gap:'20px', width: '150px'}}>
                        <input type="checkbox" id='filter' />
                        <label htmlFor='filter'>Graph View</label>
                    </div>
                    <img src={config.download} alt='' style={{ width: '2rem' }} />
                </div>
            </div>
            <TableData data={tableData} />
        </div>
    )
}

export default Table;
