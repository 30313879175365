import React from 'react';
import './card.css';

function Card(cardData) {
    
    
  return (
    <div>
      <div className='card' style={{background: cardData.data.title === 'Overall Total'? 'rgba(47, 46, 46, 1)':'rgba(255, 255, 255, 1)', color: cardData.data.color}}>
            <div className="card-title">
                { cardData.data.icon && (<img src={cardData.data.icon} alt='icon' style={{height:'100%'}}/>)}
                <p>{cardData.data.title}</p>
            </div>
            <div className="card-body" style={{ color: cardData.data.title === 'Overall Total'?'white':'rgba(67, 67, 67, 1)'}}>
                <div className='card-body-content'>
                    <span className='countTitle'>Work Count</span>
                    <hr/>
                    <span className='countValue'>{cardData.data.work_count}</span>
                </div>
                <div className='card-body-content'>
                    <span className='countTitle'>Outcome</span>
                    <hr/>
                    <span className='countValue'>{cardData.data.outcome}</span>
                </div>
            </div>
            {cardData.data.project_status && (<div className='card-footer' style={{background: cardData.data.footerColor}}>
                <div className='card-footer-wraper'>
                    <div style={{borderRight: `1px solid ${cardData.data.color}`}}>
                        <span className='card-footer-title'>Ongoing</span>
                        <span className='card-footer-value'>{cardData?.data.project_status.ongoing}</span>
                    </div>
                    <div style={{borderRight: `1px solid ${cardData.data.color}`}}>
                        <span className='card-footer-title'>Upcoming</span>
                        <span className='card-footer-value'>{cardData?.data.project_status.upcoming}</span>
                    </div>
                    <div>
                        <span className='card-footer-title'>Completed</span>
                        <span className='card-footer-value'>{cardData?.data.project_status.completed}</span>
                    </div>
                </div>
            </div>)}
        </div>
    </div>
  )
}

export default Card;
