import logo from './assets/logo.svg';
import activeDashboard from './assets/active_dashboard.svg';
import inactiveDashboard from './assets/inactive_dashboard.svg';
import activePiechart from './assets/active_pie_chart.svg';
import inactivePiechart from './assets/inactive_pie_chart.svg';
import logout from './assets/logout.svg';
import filter from './assets/filter.svg';
import artwork from './assets/artwork.svg';
import video from './assets/video.svg';
import content from './assets/content.svg';
import web from './assets/web.svg';
import artDirection from './assets/artDirection.svg';
import shoot from './assets/shoot.svg';
import clm from './assets/clm.svg';
import calendar from './assets/calendar.svg';
import filter_close from './assets/filter_close.svg';
import swap from './assets/swap.svg';
import verticals from './assets/menu-square.svg'
import calenderBlack from './assets/calendar-black.svg';
import settings from './assets/settings.svg'
import artist from './assets/artist.svg';
import downArrow from './assets/downArrow.svg';
import download from './assets/downloads.svg';
import close from './assets/close.svg';

const config  = {
    "logo": logo,
    "activeDashboard": activeDashboard,
    "inactiveDashboard": inactiveDashboard,
    "activePiechart": activePiechart,
    "inactivePiechart": inactivePiechart,
    "logout": logout,
    "filter": filter,
    "artwork": artwork,
    "video": video,
    "content": content,
    "web": web,
    "artDirection": artDirection,
    "shoot": shoot,
    "clm": clm,
    "calendar" : calendar,
    "filter_close": filter_close,
    "swap": swap,
    "verticals": verticals,
    "calenderBlack": calenderBlack,
    "settings": settings,
    "artist": artist,
    "downArrow": downArrow,
    "download": download,
    "close": close
}

export default config;