import React from 'react';
import './sidebar.css';
import config from '../config';

function Sidebar({ activeView, onSwitchView }) {
  return (
    <div className='sidebar'>
      <div className='logo-container'>
        <img src={config.logo} alt='logo' style={{"placeSelf":'center'}} />
      </div>
      <div className='selection-container'>
        <div>
          <span>MAIN</span>
        </div>
        <div onClick={() => onSwitchView("dashboard")}> 
          <img src={activeView === 'dashboard'?config.activeDashboard:config.inactiveDashboard} alt=''/>
        </div>
        <div onClick={() => onSwitchView("insight")}>
          <img src={activeView === 'insight'?config.activePiechart:config.inactivePiechart} alt=''/>
        </div>
      </div>
      <div className='logout-container'>
        <div>
          <span>LOGOUT</span>
        </div>
        <div>
          <img src={config.logout} alt='logout'/>
        </div>
      </div>
    </div>
  )
}

export default Sidebar;
