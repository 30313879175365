import React, {useState, useEffect} from 'react';
import './insights.css';
import InsightsCard from './insightsCard';
import config from '../config';
import Table from './table';

function Insights() {
  const [selectedCards, setSelectedCards] = useState([]);
  var Data = {
    title: 'Artwork',
    color: 'rgba(43, 99, 77, 1)',
    hoverColor: 'rgba(43, 99, 77, 0.21)',
    footerColor: 'rgba(217, 237, 229, 0.4)',
    icon: config.artwork,
    work_count: 9393,
    outcome: 6227,
    project_status: {
      upcoming: 24,
      ongoing: 21,
      completed: 9348
    }
  }

  const handleCardSelect = (card) => {
    setSelectedCards((prevSelectedCards) => {
      if (!card) {
        return [];
      }
      if (prevSelectedCards.find((selected) => selected.title === card?.title)) {
        // If card is already selected, deselect it by removing it from the array
        return prevSelectedCards.filter((selected) => selected.title !== card?.title);
      } else {
        // If card is not selected, add it to the array
        return [...prevSelectedCards, card];
      }
    });
  };

  useEffect(() => {
    if (selectedCards.length) {
      console.log("Selected Cards:", selectedCards.map(card => card.title));
    } else {
      console.log("No cards selected");
    }
  }, [selectedCards]);

  return (
    <div className='insights transition-container'>
      <div>
        <header>
          <p>Digilabs MIS</p>
        </header>
        <div className='card_row'>
          <InsightsCard data={Data} onCardSelect={handleCardSelect} />
          <InsightsCard data={Data} onCardSelect={handleCardSelect}/>
          <InsightsCard data={Data} onCardSelect={handleCardSelect}/>
          <InsightsCard data={Data} onCardSelect={handleCardSelect}/>
        </div>
        <div style={{ marginTop: '1rem' }}>
          <Table />
        </div>
      </div>
      <div className='filter_section'>
        <InsightsCard data={Data} />
        <div className='filter_selection_wrapper'>
          <div className='filter_selection_item'>
            <img src={config.verticals} alt='' />
            <p>Verticals</p>
            <img src={config.downArrow} alt='' style={{ textAlign: 'right' }} />
          </div>
          <div className='filter_selection_item'>
            <img src={config.calenderBlack} alt='' />
            <p>Date</p>
            <img src={config.downArrow} alt='' />
          </div>
          <div className='filter_selection_item'>
            <img src={config.settings} alt='' />
            <p>Project Status</p>
            <img src={config.downArrow} alt='' />
          </div>
          <div className='filter_selection_item'>
            <img src={config.artist} alt='' />
            <p>Artist</p>
            <img src={config.downArrow} alt='' />
          </div>
        </div>
        <div style={{ height: '20%', width: '90%' }}></div>
      </div>
    </div>
  )
}

export default Insights
